<template>
    <div>
        <b-row>
            <b-col lg="3">
                <label>ノード</label>
                <b-form-select
                    :options="nodes_choice"
                    v-model="node_code"
                    @change="onChangeNode"
                >
                </b-form-select>
            </b-col>
            <b-col v-if="showDispatch" lg="4">
                <label>指定組織が見つからない場合</label>
                <b-form-select
                    :options="node_dispatch_choice"
                    v-model="node_dispatch"
                    @change="onChangeNodeDispatch"
                >
                </b-form-select>
            </b-col>
            <b-col v-if="showDispatch" lg="4">
                <label>承認者が申請者自身の場合</label>
                <b-form-select
                    :options="self_dispatch_choice"
                    v-model="self_dispatch"
                    @change="onChangeSelfDispatch"
                >
                </b-form-select>
            </b-col>
            <b-col v-else-if="node_code==='90'" lg="8">
                <label>承認者</label>
                <multiselect v-model="approver_list" :options="approver_choice" :multiple="true"
                             label="text" track-by="value" @input="onApprovalChange"
                ></multiselect>
            </b-col>
            <b-col v-if="node_code==='95'" lg="4">
                <label>申請者の役職/全員</label>
                <b-form-select
                    :options="observer_dispatch_choice"
                    v-model="observer_dispatch"
                    @change="onChangeObserverDispatch"
                >
                </b-form-select>
            </b-col>
            <b-col v-if="node_code==='95'" lg="4">
                <label>観察者</label>
                <multiselect v-model="approver_list" :options="approver_choice" :multiple="true"
                             label="text" track-by="value" @input="onApprovalChange"
                ></multiselect>
            </b-col>
        </b-row>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import {choice, constant} from '../../utils';

export default {
    props: {
        index: {type: Number},
        nodes_choice: {type: Array, default: () => []},
        approver_choice: {type: Array, default: () => []},
        workflow_node: {type: Object, default: () => ({})},
    },
    data() {
        return {
            node_code: this.workflow_node.node.code,
            node_dispatch: this.workflow_node.node_dispatch || null,
            self_dispatch: this.workflow_node.self_dispatch || null,
            observer_dispatch: this.workflow_node.observer_dispatch || null,
            approver_list: [],
            showDispatch: this.showDispatchChoice(this.workflow_node.node.code),
        };
    },
    computed: {
        node_name() {
            const n = this.nodes_choice.find(n => n.value === this.node_code)
            return n ? n.text : null;
        },
        node_dispatch_choice() {
            return [{value: null, text: constant.SELECT_OPTION_NULL}].concat(choice.nodeDispatch);
        },
        self_dispatch_choice() {
            return [{value: null, text: constant.SELECT_OPTION_NULL}].concat(choice.selfDispatch);
        },
        observer_dispatch_choice() {
            return [{value: null, text: constant.SELECT_OPTION_NULL}].concat(choice.observerDispatch);
        }
    },
    mounted: function () {
        if (this.workflow_node.approver_list && this.workflow_node.approver_list.length > 0) {
            this.approver_list = this.approver_choice.filter(o => this.workflow_node.approver_list.indexOf(o.value) >= 0)
        } else {
            this.approver_list = []
        }
    },
    methods: {
        showDispatchChoice(code) {
            return code >= '10' && code < '20';
        },
        hasApproverList(code) {
            return code === '90' || code === '95';
        },
        onChangeNode(value) {
            if (this.showDispatchChoice(value)) {
                // 課長,部長,事業部長,本部長など階級指定の場合、もし指定階級がない場合の処理
                // 例えば、事業部長を指定して、当該部署に上級事業部がない場合
                this.showDispatch = true;
            } else {
                this.showDispatch = false;
                this.node_dispatch = null;
                this.self_dispatch = null;
                this.observer_dispatch = null;
            }
            this.$emit('change', {
                index: this.index,
                node: Object.assign({}, this.workflow_node.node, {code: this.node_code, name: this.node_name}),
                approver_list: this.hasApproverList(this.node_code) ? this.approver_list.map(i => i.value) : [],
                node_dispatch: this.node_dispatch,
                self_dispatch: this.self_dispatch,
                observer_dispatch: this.observer_dispatch,
            });
        },
        onChangeNodeDispatch(value) {
            this.$emit('change', {
                index: this.index,
                node: Object.assign({}, this.workflow_node.node),
                approver_list: this.hasApproverList(this.node_code) ? this.approver_list.map(i => i.value) : [],
                node_dispatch: value,
                self_dispatch: this.self_dispatch,
                observer_dispatch: this.observer_dispatch,
            });
        },
        onChangeSelfDispatch(value) {
            this.$emit('change', {
                index: this.index,
                node: Object.assign({}, this.workflow_node.node),
                approver_list: this.hasApproverList(this.node_code) ? this.approver_list.map(i => i.value) : [],
                node_dispatch: this.node_dispatch,
                self_dispatch: value,
                observer_dispatch: this.observer_dispatch,
            });
        },
        onChangeObserverDispatch(value) {
            this.$emit('change', {
                index: this.index,
                node: Object.assign({}, this.workflow_node.node),
                approver_list: this.hasApproverList(this.node_code) ? this.approver_list.map(i => i.value) : [],
                node_dispatch: this.node_dispatch,
                self_dispatch: this.self_dispatch,
                observer_dispatch: value,
            });
        },
        onApprovalChange(values) {
            this.$emit('change', {
                index: this.index,
                node: Object.assign({}, this.workflow_node.node, {code: this.node_code, name: this.node_name}),
                approver_list: this.hasApproverList(this.node_code) ? values.map(i => i.value) : [],
                node_dispatch: null,
                self_dispatch: null,
                observer_dispatch: this.hasApproverList(this.node_code) ? this.observer_dispatch : null,
            });
        },
    },
}
</script>