<template>
    <div>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <!-- Card stats -->
        </base-header>

        <b-container fluid class="mt--7">
            <b-row>
                <b-col>
                    <b-overlay :show="loading" rounded="md">
                        <b-card no-body>
                            <b-card-header class="border-1">
                                <h3 class="mb-0">ワークフロー：{{ workflow.name }}</h3>
                            </b-card-header>

                            <b-card-body>
                                <b-form @submit.prevent>
                                    <h6 class="heading-small text-muted mb-4">基本情報</h6>
                                    <div class="pl-lg-4">
                                        <b-row>
                                            <b-col lg="6">
                                                <base-input
                                                    type="text"
                                                    label="名称"
                                                    placeholder="名称"
                                                    v-model="workflow.name"
                                                >
                                                </base-input>
                                            </b-col>
                                            <b-col lg="6">
                                                <label>フォーム</label>
                                                <b-form-select
                                                    :options="related_forms"
                                                    v-model="workflow.related_form"
                                                >
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <hr class="my-4">
                                    <h6 class="heading-small text-muted mb-4">流れ変更</h6>
                                    <div class="pl-lg-4">
                                        <b-container class="d-flex justify-content-end py-1">
                                            <b-button-group>
                                                <b-button size="sm" variant="outline-info" @click="addNode">
                                                    <b-icon icon="plus"></b-icon>
                                                    ノード追加
                                                </b-button>
                                            </b-button-group>
                                        </b-container>
                                        <b-stepper :steps="steps" @delete="handleNodeDelete" @insert="handleNodeInsert">
                                        </b-stepper>
                                    </div>
                                </b-form>
                            </b-card-body>
                            <b-card-footer>
                                <b-row>
                                    <b-col cols="12" class="d-flex justify-content-between">
                    <span>
                      <b-button variant="primary" @click="SaveWorkflow">保存</b-button>
                    </span>
                                        <span>
                      <b-button variant="dark" :to="{ name: 'workflows' }">戻る</b-button>
                    </span>
                                    </b-col>
                                </b-row>
                            </b-card-footer>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {ajax, common, config, constant} from '../../utils';
import NodeForm from '../common/NodeForm';
import Toast from '../../components/Toast';

export default {
    data() {
        return {
            loading: true,
            workflow: {},
            related_forms: [],
            approver_choice: [],
            steps: [],
            nodes_choice: [],
            max_index: 0,
        };
    },
    watch: {
        workflow: function (newItem, oldItem) { // eslint-disable-line
            this.loading = true;
        },
    },
    created: function () {
        this.getWorkflowFormsChoice();
        this.getNodesChoice();
        this.getApproverChoice();
        this.getWorkflowDetail(this.$route.params.id);
    },
    updated: function () {
        document.title = `${this.workflow.name} | ${common.getPageName(this.$route.name)}`;
    },
    methods: {
        getWorkflowDetail(id) {
            const vm = this;
            ajax.fetchGet(common.formatStr(config.api.workflow.workflow_detail, id)).then(data => {
                vm.workflow = data;
            }).then(async () => {
                const data = await ajax.fetchGet(common.formatStr(config.api.workflow.workflow_node_list, id));
                vm.max_index += (data.results.length - 1)
                vm.steps = data.results.map((item, index) => {
                    return {
                        name: item.node.name,
                        key: item.node.code === '99' ? 99 : index,
                        disabled: ['00', '99'].indexOf(item.node.code) >= 0,
                        visible: true,
                        props: {
                            index: item.node.code === '99' ? 99 : index,
                            nodes_choice: vm.nodes_choice,
                            approver_choice: vm.approver_choice,
                            workflow_node: item,
                        },
                        handleChange: vm.handleNodeChange,
                        component: NodeForm,
                    };
                });
            }).finally(() => {
                vm.loading = false;
            });
        },
        getWorkflowFormsChoice() {
            const vm = this;
            ajax.fetchGet(config.api.workflow.forms_choice).then(data => {
                vm.related_forms = data;
            });
        },
        getNodesChoice() {
            const vm = this;
            ajax.fetchGet(config.api.workflow.nodes_choice).then(data => {
                vm.nodes_choice = data;
            });
        },
        getApproverChoice() {
            const vm = this;
            ajax.fetchGet(config.api.master.approver_choice).then(data => {
                vm.approver_choice = data;
            });
        },
        addNode() {
            this.max_index += 1;
            this.steps.splice(this.steps.length - 1, 0, {
                name: '新規ノード',
                key: this.max_index,
                disabled: false,
                visible: true,
                props: {
                    index: this.max_index,
                    nodes_choice: this.nodes_choice,
                    approver_choice: this.approver_choice,
                    workflow_node: {
                        node: {},
                        workflow: parseInt(this.$route.params.id),
                        approver_list: [],
                    },
                },
                handleChange: this.handleNodeChange,
                component: NodeForm,
            });
        },
        handleNodeChange(data) {
            const index = this.steps.indexOf(this.steps.find(i => i.props.index === data.index));
            this.steps[index].name = data.node.name;
            this.steps[index].props.workflow_node.node = data.node;
            this.steps[index].props.workflow_node.approver_list = data.approver_list;
            this.steps[index].props.workflow_node.node_dispatch = data.node_dispatch;
            this.steps[index].props.workflow_node.self_dispatch = data.self_dispatch;
            this.steps[index].props.workflow_node.observer_dispatch = data.observer_dispatch;
            this.steps[index].props.workflow_node.order = data.index;
        },
        handleNodeInsert(i) {  // eslint-disable-line
            // TODO:
        },
        handleNodeDelete(i) {
            if (confirm(constant.CONFIRM.REMOVE_NODE)) {
                // this.steps.splice(i, 1)
                this.steps[i].visible = false;
                this.steps[i].props.workflow_node.is_deleted = true;
            }
        },
        SaveWorkflow() {
            if (confirm(constant.CONFIRM.SAVE_WORKFLOW)) {
                const nodes = this.steps.map(item => (item.props.workflow_node));
                const vm = this;
                ajax.fetchPut(
                    common.formatStr(config.api.workflow.workflow_detail, this.$route.params.id),
                    Object.assign({}, this.workflow, {nodes})
                ).then(() => {
                    Toast.success(constant.SUCCESS.SAVED);
                    vm.$router.push({name: 'workflows'});
                }).catch(errors => {
                    Toast.error(errors.detail)
                })
            }
        },
    }
}
</script>